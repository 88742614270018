<script setup lang="ts">
import ChevronIcon from '@/assets/svg/conversations/ChevronIcon.svg?component';
import CloseIcon from '@/assets/svg/close-24.svg?component';
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    modelValue?: string | number;
    items: Array<{ title: string; id: string | number }>;
    placeholder?: string;
    itemTitle?: string;
    itemValue?: string;
    dark?: boolean;
    plain?: boolean;
    loading?: boolean;
    renderItem?: (item: Record<string, unknown>) => string;
    customFilter?: (
      value: string,
      query: string,
      item?: { value: unknown; raw: Record<string, unknown> },
    ) => boolean | number | [number, number] | [number, number][];
  }>(),
  {
    itemTitle: 'title',
    itemValue: 'title',
  },
);

const emit = defineEmits<{
  (e: 'update:modelValue', value?: string | number): void;
}>();

const bgColor = computed(() => {
  if (props.plain) {
    return 'white';
  }

  return props.dark ? 'shade-840' : 'tint-20';
});

const color = computed(() => {
  if (props.plain) {
    return 'black';
  }

  return props.dark ? 'shade-840' : 'tint-20';
});

const classNames = computed(() => {
  if (props.plain) {
    return 'plain-autocomplete';
  }

  return props.dark ? 'dark-autocomplete' : 'light-autocomplete';
});
</script>

<template>
  <v-autocomplete
    v-bind="$attrs"
    :bg-color="bgColor"
    :class="classNames"
    :clear-icon="CloseIcon"
    :color="color"
    :custom-filter="customFilter"
    :item-title="itemTitle"
    :item-value="itemValue"
    :items="items"
    :loading="loading"
    :menu-icon="ChevronIcon"
    :menu-props="{
      contentClass: 'pt-1 !rounded-md shadow-menu',
      maxWidth: '0',
    }"
    :model-value="modelValue"
    :placeholder="placeholder"
    density="compact"
    hide-details
    variant="solo"
    @update:model-value="emit('update:modelValue', $event)"
  >
    <template #prepend-item>
      <slot name="prepend-item"></slot>
    </template>

    <template #item="{ props, item }">
      <div
        v-bind="props"
        class="select-item"
        :class="{
          'bg-highlight-50 font-bold': modelValue === item.raw.title || modelValue === item.raw.id,
        }"
      >
        <div v-if="renderItem" class="w-full" v-html="renderItem(item.raw)"></div>
        <span v-else class="truncate">{{ item.title }}</span>
      </div>
    </template>
  </v-autocomplete>
</template>

<style lang="postcss" scoped>
/* Style inner elements differently based on light/dark mode*/
:deep(.v-field) {
  @apply transition-colors duration-300;
  @apply border border-transparent;
  box-shadow: none;

  .v-field__input {
    input {
      @apply bg-transparent text-sm leading-6;
    }
  }
  .v-icon {
    @apply transition-all duration-300;
    @apply hover:opacity-100;
  }
}

.light-autocomplete :deep(.v-field) {
  @apply border border-tint-80 bg-tint-20;
  .v-field__input {
    input {
      @apply text-shade-900 placeholder:text-[13px] placeholder:text-shade-800  placeholder:opacity-95;
      @apply disabled:placeholder:text-tint-200;
    }
  }
  &.v-field--focused {
    @apply !border-highlight-500;
  }
  &:hover {
    @apply border-shade-500;
  }
  .v-autocomplete__selection-text {
    @apply text-sm leading-6 text-shade-900;
  }
  .v-icon {
    @apply text-shade-900;
  }
}

.dark-autocomplete :deep(.v-field) {
  @apply border border-transparent;
  .v-field__input {
    input {
      @apply text-tint-0 placeholder:text-[13px] placeholder:text-shade-800  placeholder:opacity-95;
      @apply disabled:placeholder:text-tint-200;
    }
  }
  &.v-field--focused {
    @apply !border-highlight-500;
  }
  &:hover {
    @apply border-tint-0;
  }
  .v-field__append-inner {
    @apply bg-transparent text-tint-200;
  }
  .v-autocomplete__selection-text {
    @apply text-sm leading-6 text-tint-0;
  }
  .v-field__clearable {
    .v-icon {
      @apply text-tint-300;
    }
  }
}

.plain-autocomplete {
  :deep(.v-field) {
    @apply w-full rounded-md border border-tint-80;
    box-shadow: none;

    .v-field__input {
      input {
        @apply bg-transparent text-shade-900 placeholder:text-shade-800;
        @apply disabled:placeholder:text-tint-200;
      }
    }

    &.v-field--focused {
      @apply outline-none ring-2 ring-highlight-500;
    }

    .v-icon {
      @apply text-shade-900 transition-all duration-300;
      @apply hover:opacity-100;
    }

    .v-autocomplete__selection-text {
      @apply text-sm leading-6 text-shade-900;
    }
  }
}

.select-item {
  @apply mb-0.5 flex h-8 min-h-[40px] cursor-pointer items-center justify-start px-3 py-1 text-sm hover:bg-tint-20;
}
</style>
