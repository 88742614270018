<script lang="ts" setup>
withDefaults(
  defineProps<{
    modelValue: boolean;
    title?: string;
    subtitle?: string;
    showHeading?: boolean;
    showActions?: boolean;
    showCloseButton?: boolean;
    showCancelButton?: boolean;
    showSecondaryButton?: boolean;
    isLoading?: boolean;
    isSubmitDisabled?: boolean;
    cancelButtonText?: string;
    secondaryButtonText?: string;
    submitButtonText?: string;
  }>(),
  {
    showHeading: true,
    showActions: true,
    cancelButtonText: 'Cancel',
    secondaryButtonText: 'Secondary',
    submitButtonText: 'Submit',
  },
);

const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'submit'): void;
  (e: 'secondary'): void;
}>();

function cancel() {
  emit('cancel');
}

function submit() {
  emit('submit');
}

function secondary() {
  emit('secondary');
}
</script>

<template>
  <v-dialog :model-value="modelValue" max-width="600px" height="550px" persistent>
    <div class="rounded-2xl bg-white p-8">
      <slot name="header">
        <div v-if="showHeading">
          <!-- Close button -->
          <img
            v-if="showCloseButton"
            class="float-right mt-1 cursor-pointer"
            src="@/assets/svg/close-black.svg"
            alt="close"
            width="20"
            height="20"
            @click="cancel"
          />

          <!-- Title -->
          <div v-if="title" class="font-serif text-2xl font-black text-copilot">{{ title }}</div>

          <!-- Subtitle -->
          <div v-if="subtitle" class="text-base text-shade-800">{{ subtitle }}</div>
        </div>
      </slot>

      <!-- Default Slot -->
      <slot />

      <slot name="actions">
        <div
          v-if="showActions"
          :class="{
            'flex items-center gap-4': true,
            'justify-between': showSecondaryButton && showCancelButton,
            'justify-end': !(showSecondaryButton && showCancelButton),
          }"
        >
          <!-- Cancel button -->
          <v-btn
            v-if="showCancelButton"
            :ripple="false"
            class="modal-button-text"
            variant="flat"
            @click="cancel"
            >{{ cancelButtonText }}</v-btn
          >

          <div class="flex items-center justify-end gap-4">
            <!-- Secondary button -->
            <v-btn
              v-if="showSecondaryButton"
              :ripple="false"
              class="modal-button-secondary"
              variant="flat"
              @click="secondary"
              >{{ secondaryButtonText }}</v-btn
            >

            <!-- Submit button -->
            <v-btn
              :ripple="false"
              :loading="isLoading"
              :disabled="isSubmitDisabled"
              class="modal-button-primary"
              variant="flat"
              @click="submit"
              >{{ submitButtonText }}</v-btn
            >
          </div>
        </div>
      </slot>
    </div>
  </v-dialog>
</template>
