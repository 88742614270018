<template>
  <div
    :class="{
      'score-card-wrapper inline-block flex w-fit rounded': true,
      'bg-highlight-50 bg-tint-0': isSelected && !shouldBgBeWhite,
      'bg-tint-40': !isSelected && !shouldBgBeWhite,
      'bg-white': shouldBgBeWhite,
    }"
  >
    <div
      class="score-card flex items-center"
      :class="{
        'flex w-fit space-x-2 rounded': true,
        'bg-tint-0': isSelected && !shouldBgBeWhite,
        'bg-tint-40': !isSelected && !shouldBgBeWhite,
        'bg-white': shouldBgBeWhite,
      }"
    >
      <div
        class="applicant-title ml-1 min-w-0 flex-1 truncate font-semibold text-shade-880"
        v-if="jobTitle"
        :title="jobTitle"
      >
        {{ jobTitle }}
      </div>
      <score-rating :width="16" :height="16" v-if="hasScore" :score="score"> </score-rating>
      <div v-else class="font-size-10 rounded-sm bg-shade-900 px-0.5">N/A</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ScoreRating from '@/components/Conversations/Application/ScoreRating.vue';
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    isSelected: boolean;
    shouldBgBeWhite: boolean;
    jobTitle?: string;
    score?: number;
  }>(),
  {
    score: 0,
    jobTitle: '',
    shouldBgBeWhite: false,
  },
);

const hasScore = computed(() => typeof props.score === 'number');
</script>

<style lang="postcss" scoped>
.applicant-title {
  max-width: 188px;
  line-height: 150%;
  font-size: 10px;
}
.score-card-wrapper {
  padding: 1px;
}
.score-card {
  padding: 3px;
}

.font-size-10 {
  font-size: 10px;
}
</style>
