import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { CriteriaColor } from '@/components/Conversations/Application/ScoreChip';
import type { UserProfile } from '@factoryfixinc/ats-interfaces';
import type { MessagingSource } from '@/core/sourcing/messaging/types/messaging-source.type';
export const useDialogStore = defineStore('dialog', () => {
  const isCreateProjectDialogOpen = ref(false);

  // Project Description Dialog
  const projectDescriptionOverlayProps = ref<{
    isOpen: boolean;
    projectId?: number;
    jobId?: number;
  }>({
    isOpen: false,
    projectId: undefined,
    jobId: undefined,
  });

  const addProToJobModalProps = ref<AddProToJobModalProps>({
    isOpen: false,
  });

  const copilotScoreFeedbackModalProps = ref<CopilotScoreFeedbackModalProps>({
    isOpen: false,
  });

  return {
    addProToJobModalProps,
    isCreateProjectDialogOpen,
    projectDescriptionOverlayProps,
    copilotScoreFeedbackModalProps,
  };
});

export type CopilotScoreFeedbackModalProps =
  | { isOpen: false }
  | {
      isOpen: true;
      jobApplicantId: number;
      copilotScore: number;
      copilotBlurb: string;
      appContext?: string;
      copilotChips?: {
        title: string;
        text: string;
        color: CriteriaColor;
      }[];
      copilotChipsAsDictionary?: Record<string, string>;
    };

export type AddProToJobModalProps = {
  isOpen: boolean;
  showToField?: boolean;
  showMessageField?: boolean;
  excludedJobIds?: number[];
  pros?: Array<Pick<UserProfile, 'id' | 'nameFirst' | 'nameLast'> & { source?: MessagingSource }>;
};
