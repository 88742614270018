import ProUnlockPersistence from './pro-unlock.persistence';
import type { ProUnlockResult, ProUnlockSource } from './types/pro-unlock-source.type';
import MeService from '@/core/shared/me/me.service';
import { SearchService } from '../search/search.service';
import SelectedCandidateService from '../selected-candidate/selected-candidate.service';

export default class ProUnlockService {
  private persistence = new ProUnlockPersistence();
  private searchService = new SearchService();
  private meService = new MeService();
  private selectedCandidateService = new SelectedCandidateService();

  async unlockProUserProfiles(
    userProfileIds: number[],
    source: ProUnlockSource,
  ): Promise<ProUnlockResult | undefined> {
    const employerId = this.meService.employer?.id;
    if (!employerId || !userProfileIds.length || !source) return;

    const results = await this.persistence.unlockProUserProfiles({
      employerId,
      userProfileIds,
      source,
    });
    // this.$mixpanelAnalytics.proUnlock({
    //   source,
    //   numberOfPros: userProfileIds.length,
    // });
    this.searchService.searchCandidates();

    const selectedCandidate = this.selectedCandidateService.candidate;
    if (selectedCandidate?.id) {
      this.selectedCandidateService.fetchCandidate(selectedCandidate.id);
    }

    return results;
  }
}
