<script lang="ts" setup>
import BaseModal from '@/components/Shared/Modals/BaseModal.vue';
import { DialogService } from '@/core/shared/dialog/dialog.service';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';
import { TrackingActionName } from '@/core/shared/tracking/tracking-actions';
import TrackingService from '@/core/shared/tracking/tracking.service';
import { computed, ref } from 'vue';
import SingleStarRatingSvg from '@/assets/svg/conversations/single-star-rating-32.svg?component';
import { ErrorService } from '@/core/shared/errors/error.service';
import { CopilotScoreFeedbackSubmittedSelection } from '@/core/shared/tracking/enums';

const dialogService = new DialogService();
const modalProps = computed(() => dialogService.copilotScoreFeedbackModalProps);
const isValidForm = computed(() => userScore.value && userScore.value > 0);

const userScore = ref<number>();
const userComments = ref<string>();
const isLoading = ref(false);

async function submit() {
  if (!modalProps.value.isOpen) {
    return;
  }

  try {
    // Send tracking event with thumbsdown feedback and user score and comments
    isLoading.value = true;
    await TrackingService.trackAction(
      TrackingActionName.COPILOT_SCORE_FEEDBACK_SUBMITTED,
      {
        jobApplicantId: modalProps.value.jobApplicantId,
        copilotScore: modalProps.value.copilotScore,
        copilotBlurb: modalProps.value.copilotBlurb,
        feedback: CopilotScoreFeedbackSubmittedSelection.THUMBSDOWN,
        appContext: modalProps.value.appContext,
        userScore: userScore.value,
        userComments: userComments.value,
        ...(modalProps.value.copilotChipsAsDictionary ?? {}),
      },
      { throwsOnError: true },
    );

    showInAppNotification();
    resetForm();
    closeModal();
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Something went wrong. Please try again');
  } finally {
    isLoading.value = false;
  }
}

function cancel() {
  if (!modalProps.value.isOpen) {
    return;
  }

  // Send tracking event with thumbsup feedback but without user score or comments
  TrackingService.trackAction(TrackingActionName.COPILOT_SCORE_FEEDBACK_SUBMITTED, {
    jobApplicantId: modalProps.value.jobApplicantId,
    copilotScore: modalProps.value.copilotScore,
    copilotBlurb: modalProps.value.copilotBlurb,
    feedback: CopilotScoreFeedbackSubmittedSelection.THUMBSUP,
    appContext: modalProps.value.appContext,
    ...(modalProps.value.copilotChipsAsDictionary ?? {}),
  });

  resetForm();
  closeModal();
}

function showInAppNotification() {
  SnackbarService.showSnackbar({
    text: 'Thanks for your feedback!',
    horizontalPosition: 'center',
    prependIcon: 'success',
    prependIconCss: 'text-tint-0',
  });
}

function resetForm() {
  userScore.value = undefined;
  userComments.value = undefined;
}

function closeModal() {
  dialogService.copilotScoreFeedbackModalProps = {
    isOpen: false,
  };
}
</script>

<template>
  <BaseModal
    :model-value="modalProps.isOpen"
    :is-submit-disabled="!isValidForm"
    :is-loading="isLoading"
    :show-cancel-button="true"
    title="Give feedback"
    @cancel="cancel"
    @submit="submit"
    @clear="resetForm"
  >
    <div class="mt-4 flex flex-col">
      <!-- Score-->
      <div
        class="flex items-center justify-between rounded-md border border-tint-40 bg-tint-20 p-4"
      >
        <span>What would you rate this candidate?</span>

        <v-rating v-model="userScore" hover half-increments :length="5">
          <template #item="item">
            <div @click="item.props.onClick">
              <SingleStarRatingSvg
                :class="{
                  'mx-0.5 text-tint-80': true,
                  'text-highlight-500': item.isFilled || item.isHovered,
                }"
              />
            </div>
          </template>
        </v-rating>
      </div>

      <!-- Comments -->
      <div class="mb-4 mt-6">
        <textarea
          v-model="userComments"
          class="w-full resize-none rounded-md border border-tint-80 px-4 py-4 text-shade-900 placeholder:text-shade-800 focus:outline-none focus:ring-2 focus:ring-highlight-500"
          placeholder="Share your feedback or ideas on Copilot..."
          rows="4"
        />
      </div>
    </div>
  </BaseModal>
</template>
