<script setup lang="ts">
import ContactInfo from '@/components/Sourcing/ProPreviewPanel/ContactInfo.vue';
import { computed, ref, watch } from 'vue';
import WorkExperiencePanel from '@/components/Sourcing/ProPreviewPanel/WorkExperiencePanel.vue';
import EducationPanel from '@/components/Sourcing/ProPreviewPanel/EducationPanel.vue';
import type { GatedUserProfile } from '@/core/sourcing/selected-candidate/types/gated-user-profile.type';
import SelectedCandidateService from '@/core/sourcing/selected-candidate/selected-candidate.service';
import PanelDivider from '@/components/Sourcing/ProPreviewPanel/PanelDivider.vue';
import SimpleExpansionPanel from '@/components/Shared/ExpansionPanel/SimpleExpansionPanel.vue';
import NotesPanel from '@/components/Sourcing/ProPreviewPanel/NotesPanel.vue';
import ProNotesService from '@/core/shared/pro-notes/pro-notes.service';

import { ProUnlockSource } from '@/core/sourcing/pro-unlock/types/pro-unlock-source.type';
import SubscriptionService from '@/core/shared/subscription/subscription.service';
import { MessagingService } from '@/core/sourcing/messaging/messaging.service';
import { MessagingSource } from '@/core/sourcing/messaging/types/messaging-source.type';
import PanelActionButton from '@/components/Search/PanelActionButton.vue';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';

import ResumeDialog from '@/components/Shared/Dialogs/ResumeDialog.vue';
import TrackingService from '@/core/shared/tracking/tracking.service';

import { TrackingActionName } from '@/core/shared/tracking/tracking-actions';
import { isProRecentlyActive } from '@/core/sourcing/utils/dates';
import ProUnlockService from '@/core/sourcing/pro-unlock/pro-unlock.service';

import { ErrorService } from '@/core/shared/errors/error.service';
import { InternalError } from '@/core/shared/errors/internal.error';
import { HttpStatus } from '@/core/shared/errors/enums/http-status.enum';
import { ProUnlockResultStatus } from '@/core/sourcing/pro-unlock/types/pro-unlock-source.type';

const emit = defineEmits<{
  (e: 'open:messaging', value: boolean): void;
}>();

const selectedCandidateService = new SelectedCandidateService();

const proNotesService = new ProNotesService();
const proUnlockService = new ProUnlockService();
const messagingService = new MessagingService();

const isResumeDialogOpened = ref(false);

const isFetchingCandidate = computed(() => selectedCandidateService.isFetchingCandidate);
const candidate = computed<GatedUserProfile | undefined>(() => selectedCandidateService.candidate);
const isUnlocked = computed(() => candidate.value?.unlocked);

const hasPPCSelection = computed(() => messagingService.selectedPPCUserProfiles.length > 0);
const workExperience = computed(() => selectedCandidateService.workExperiences);
const education = computed(() => selectedCandidateService.education);
const industries = computed(() => selectedCandidateService.industries);
const knowledgeDisciplines = computed(() => selectedCandidateService.knowledgeDisciplines);
const machinesAndTechnologies = computed(() => selectedCandidateService.machinesAndTechnologies);
const notesWithJobHeaders = computed(() => proNotesService.notesWithJobHeaders);

const unlockProUserProfile = async () => {
  try {
    selectedCandidateService.isFetchingCandidate = true;
    const userProfileIdToUnlock = candidate.value?.id;
    if (!userProfileIdToUnlock) return;

    const subscriptionService = new SubscriptionService();
    const shouldDisplayTrialModal = subscriptionService.shouldDisplayTrialModal({
      unlockCount: 1,
    });
    if (shouldDisplayTrialModal) {
      messagingService.setIsTrialUpgradeModalVisible({
        visible: true,
        source: ProUnlockSource.PPP,
      });
      return;
    }
    await proUnlockService.unlockProUserProfiles([userProfileIdToUnlock], ProUnlockSource.PPP);
  } catch (error) {
    ErrorService.captureException(error);
    if (
      error instanceof InternalError &&
      error.status === HttpStatus.FORBIDDEN &&
      error.data?.status === ProUnlockResultStatus.ERROR_LIMIT_REACHED
    ) {
      SnackbarService.criticalAlt(
        '<b>Unlock limit reached:</b> Your unlocks will refresh at the start of your next billing cycle.',
      );
    } else {
      SnackbarService.critical('Could not unlock user profile, please try again later.');
    }
  } finally {
    selectedCandidateService.isFetchingCandidate = false;
    trackUnlock();
  }
};

function trackUnlock() {
  TrackingService.instance.trackActionEvent(TrackingActionName.UNLOCK, {
    recently_active: isProRecentlyActive(selectedCandidateService.candidate?.lastActiveTs),
  });
}
const openMessaging = async () => {
  try {
    const userProfileIdToUnlock = candidate.value?.id;
    if (!userProfileIdToUnlock) return;

    messagingService.selectedProUserProfiles = [];
    await messagingService.addProUserProfileToSelectedList({
      profileId: userProfileIdToUnlock.toString(),
      nameFirst: candidate.value?.nameFirst ?? '',
      nameLast: candidate.value?.nameLast ?? '',
      unlocked: candidate.value?.unlocked,
      lastActiveTs: candidate.value?.lastActiveTs ?? '',
      source: MessagingSource.PPP,
    });
    emit('open:messaging', true);
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Could not unlock user profile');
  }
};

function openResumeDialog() {
  isResumeDialogOpened.value = true;
}

function handleResumeError() {
  isResumeDialogOpened.value = false;
  SnackbarService.critical('Error loading resume. Please try again later.');
}

watch(candidate, async (newVal) => {
  if (newVal) {
  }
});
</script>

<template>
  <div
    class="flex flex-col overflow-auto px-3 py-5"
    :class="!candidate ? 'h-full items-center justify-center' : ''"
  >
    <!-- Loading candidate -->
    <template v-if="isFetchingCandidate">
      <div class="pa-4 w-full">
        <v-skeleton-loader type="heading" class="my-4 mb-10" />
        <v-skeleton-loader v-for="i in 7" :key="i" type="text, list-item-two-line" class="my-4" />
      </div>
    </template>

    <!-- No candidate selected -->
    <template v-else-if="!candidate">
      <p class="text-shade-600">Select a candidate to view details</p>
    </template>

    <!-- Candidate info -->
    <template v-else>
      <ContactInfo
        :candidate="candidate"
        :is-unlocked="isUnlocked"
        @click:view-resume="openResumeDialog"
      />
      <ResumeDialog
        v-model="isResumeDialogOpened"
        :user-profile="candidate"
        @rendering-failed="handleResumeError"
        @loading-failed="handleResumeError"
      />
      <PanelDivider />
      <WorkExperiencePanel :work-experience="workExperience" />
      <PanelDivider />
      <EducationPanel :education="education" />
      <PanelDivider />
      <SimpleExpansionPanel panel-title="Industries" :items="industries" />
      <PanelDivider />
      <SimpleExpansionPanel panel-title="Knowledge Disciplines" :items="knowledgeDisciplines" />
      <PanelDivider />
      <SimpleExpansionPanel
        panel-title="Machines / Technologies"
        :items="machinesAndTechnologies"
      />
      <PanelDivider />
      <NotesPanel :notes="notesWithJobHeaders" class="mb-[65px]" />

      <div
        class="absolute bottom-0 left-0 flex w-full items-center justify-center border-t border-tint-40 bg-white px-5 py-3"
      >
        <template v-if="isUnlocked">
          <!-- Message Btns -->
          <div class="relative w-full">
            <PanelActionButton
              v-if="!hasPPCSelection"
              variant="Add to job"
              :loading="isFetchingCandidate"
              @click="openMessaging"
            />
          </div>
        </template>

        <template v-else>
          <div class="flex w-full flex-col gap-2">
            <PanelActionButton
              variant="Unlock"
              :loading="isFetchingCandidate"
              @click="unlockProUserProfile"
            />
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<style scoped></style>
