<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';

import CopilotActivationService from '@/core/jobs/copilot-activation/copilot-activation.service';
import TaxonomyService from '@/core/shared/taxonomy/taxonomy.service';
import JobTitleService from '@/core/shared/job-title/job-title.service';

import FormSection from './FormSection.vue';
import SuggestionCombobox from './SuggestionCombobox.vue';
import type { TaxonomyBrand } from '@factoryfixinc/ats-interfaces';
import PlusIcon from '@/assets/svg/plus-16.svg?component';
import { clone } from '@/utils/objects/clone.util';

const jobTitleService = new JobTitleService();
const copilotActivationService = new CopilotActivationService();
const taxonomyService = new TaxonomyService();

const props = defineProps<{
  isDisabled: boolean;
}>();

const suggestedKnowledgeDisciplinesIds = ref<number[]>([]);
const suggestedTechnologiesMachinesIds = ref<number[]>([]);

const selectedJobTitle = computed(() => {
  const jobTitle = jobTitleService.jobTitles.find(
    (jobTitle) => jobTitle.id === copilotActivationService.jobTitleId,
  );

  return jobTitle;
});

const knowledgeDisciplineItems = computed(() => {
  const newArray = clone(taxonomyService.knowledgeDisciplines);
  return newArray.sort((a, b) => (a.title > b.title ? 1 : a.title < b.title ? -1 : 0));
});
const machineItems = computed(() => {
  const newArray = clone(taxonomyService.machines);
  return newArray.sort((a, b) => (a.title > b.title ? 1 : a.title < b.title ? -1 : 0));
});
const brandItems = computed(() => {
  const newArray = clone(taxonomyService.brands);
  return newArray.sort((a, b) => (a.title > b.title ? 1 : a.title < b.title ? -1 : 0));
});
const selectedMachinesWithBrandItems = computed(() => {
  const filteredMachines = machineItems.value.filter((machine) => {
    return copilotActivationService.machines.includes(machine.id);
  });
  return filteredMachines.filter((machine) => {
    const brands = machine.taxonomyMachineTaxonomyBrandMaps;

    return Array.isArray(brands) && brands.length > 0;
  });
});
const suggestedBrandsGivenSelectedMachines = computed(() => {
  // We are going to create an Object with arrays where each array represent a
  // selected machine and its suggested brands.
  const machineIdBrandsMap: {
    [key: number]: (TaxonomyBrand & { selected: boolean })[];
  } = {};

  for (const machine of selectedMachinesWithBrandItems.value) {
    const brands: (TaxonomyBrand & { selected: boolean })[] = [];
    if (
      Array.isArray(machine.taxonomyMachineTaxonomyBrandMaps) &&
      machine.taxonomyMachineTaxonomyBrandMaps.length > 0
    ) {
      for (const brandMap of machine?.taxonomyMachineTaxonomyBrandMaps) {
        const brand = brandItems.value.find((brand) => brand.id === brandMap.taxonomyBrandId);
        if (brand) {
          const isSelected = isBrandSelectedForMachine(brand.id, machine.id);
          brands.push({ ...brand, selected: isSelected });
        }
      }
      machineIdBrandsMap[machine.id] = brands;
    }
  }

  return machineIdBrandsMap;
});
const hasBrandSuggestions = computed(() => {
  return Object.keys(suggestedBrandsGivenSelectedMachines.value).length > 0;
});

function getMachineObjectForMachineId(machineId: number) {
  return machineItems.value.find((machine) => machine.id === machineId);
}

function isBrandSelectedForMachine(brandId: number, machineId: number) {
  const brands = copilotActivationService.brandsPerMachine[machineId] ?? [];
  return brands.includes(brandId);
}

function handleKnowledgeDisciplineUpdate(value: unknown) {
  if (Array.isArray(value)) {
    copilotActivationService.knowledgeDisciplines = value as number[];
  } else {
    copilotActivationService.knowledgeDisciplines = [];
  }
}

function handleMachineUpdate(value: unknown) {
  if (Array.isArray(value)) {
    copilotActivationService.machines = value as number[];

    // Remove machines that are not selected anymore
    const usingMachineIdsForBrands = Object.keys(copilotActivationService.brandsPerMachine);
    for (const machineId of usingMachineIdsForBrands) {
      const numberMachineId = Number(machineId);
      if (!copilotActivationService.machines.includes(numberMachineId)) {
        delete copilotActivationService.brandsPerMachine[numberMachineId];
      }
    }

    // Add new machines
    for (const machineId of copilotActivationService.machines) {
      if (!usingMachineIdsForBrands.includes(String(machineId))) {
        copilotActivationService.brandsPerMachine[machineId] = [];
      }
    }
  } else {
    copilotActivationService.machines = [];
    // Clean up brands
    copilotActivationService.brandsPerMachine = {};
  }
}

function handleToggleBrandSelection(brandId: number, machineId: number) {
  const brands = copilotActivationService.brandsPerMachine[machineId] ?? [];
  const brandIndex = brands.indexOf(brandId);

  if (brandIndex === -1) {
    brands.push(brandId);
  } else {
    brands.splice(brandIndex, 1);
  }

  copilotActivationService.brandsPerMachine[machineId] = brands;
}

onMounted(async () => {
  await Promise.all([
    taxonomyService.fetchKnowledgeDisciplines(),
    taxonomyService.fetchMachines(),
    taxonomyService.fetchBrands(),
  ]);
});

watch(selectedJobTitle, async (value) => {
  if (!value) {
    return;
  }

  const suggestions = await taxonomyService.fetchSuggestedTechnologiesMachines(value.id);

  suggestedTechnologiesMachinesIds.value = suggestions.map(
    (suggestion) => suggestion.taxonomyMachineId,
  );

  if (!value?.taxonomyCategoryId) {
    return;
  }

  const knowledgeDisciplineSuggestions = await taxonomyService.fetchSuggestedKnowledgeDisciplines(
    value.taxonomyCategoryId,
  );

  suggestedKnowledgeDisciplinesIds.value = knowledgeDisciplineSuggestions.map(
    (suggestion) => suggestion.taxonomyKnowledgeDisciplineId,
  );
});

const selectBrandTitle = (machineId: number) => {
  const brand = getMachineObjectForMachineId(Number(machineId))?.title;
  return props.isDisabled ? `Brands for ${brand}` : `Select brands for ${brand}`;
};
</script>

<template>
  <FormSection id="must-have-skills" title="Desired skills">
    <!-- KNOWLEDGE DISCIPLINES -->
    <p class="mb-0.5 font-sans text-xs font-bold leading-[18px] text-shade-880">
      Knowledge Disciplines
    </p>

    <SuggestionCombobox
      :model-value="copilotActivationService.knowledgeDisciplines"
      :items="knowledgeDisciplineItems"
      :suggestions="suggestedKnowledgeDisciplinesIds"
      placeholder="Select knowledge disciplines"
      @update:model-value="handleKnowledgeDisciplineUpdate"
      :class="{ disabled: isDisabled }"
    />

    <!-- TECHNOLOGIES MACHINES -->
    <p class="mb-0.5 mt-6 font-sans text-xs font-bold leading-[18px] text-shade-880">
      Technologies & Machines
    </p>
    <SuggestionCombobox
      :model-value="copilotActivationService.machines"
      :items="machineItems"
      :suggestions="suggestedTechnologiesMachinesIds"
      placeholder="Select technologies & machines "
      @update:model-value="handleMachineUpdate"
      :class="{ disabled: isDisabled }"
    />

    <!-- Brands -->
    <div v-if="hasBrandSuggestions">
      <p class="-mb-3.5 mt-6 font-sans text-xs font-bold leading-[18px] text-shade-880">Brands</p>
      <div v-for="(brandMaps, machineId) of suggestedBrandsGivenSelectedMachines" :key="machineId">
        <p class="mb-0.5 mt-6 font-sans text-sm font-normal leading-[18px] text-shade-800">
          {{ selectBrandTitle(machineId) }}
        </p>
        <div class="flex flex-wrap gap-1.5" :class="{ disabled: isDisabled }">
          <div
            v-for="brand in brandMaps"
            :key="brand.id"
            class="brand-item"
            :class="{
              'selected-brand ': brand.selected,
            }"
            @click="handleToggleBrandSelection(brand.id, Number(machineId))"
          >
            <!-- @click="selectSuggestion(suggestion.id)" -->
            <span class="inline-block h-5 text-sm leading-5"> {{ brand.title }}</span>
            <PlusIcon
              class="ml-1.5 mt-0.5 inline-block h-4 text-black"
              :class="{
                'rotate-45': brand.selected,
              }"
            />
          </div>
        </div>
      </div>
    </div>
  </FormSection>
</template>

<style lang="scss" scoped>
.disabled {
  @apply pointer-events-none cursor-default  opacity-85;
  :deep(.v-field) {
    @apply border-[1px] border-solid border-tint-80 bg-tint-40;
    input {
      @apply opacity-0;
    }
  }
  :deep(.suggestions) {
    display: none;
  }
  .brand-item {
    display: none;
  }
  :deep(svg.rotate-45) {
    display: none;
  }
}
.v-input :deep(.v-field),
.v-input--horizontal :deep(.v-field) {
  border-radius: 6px;

  .v-field__outline {
    @apply text-tint-80;
  }
  input {
    @apply font-sans text-sm font-normal leading-[21px] text-shade-880;
  }
  input::placeholder {
    @apply text-shade-800;
    opacity: 1;
  }

  &:hover {
    .v-field__outline {
      @apply text-shade-840;
    }
  }
}

.v-input :deep(.v-field.v-field--focused),
.v-input--horizontal :deep(.v-field.v-field--focused) {
  .v-field__outline {
    @apply text-highlight-500;
  }
}
.brand-item {
  @apply mb-0.5 box-border flex h-8 cursor-pointer content-center rounded-full border border-tint-80 px-3 py-1.5;
}
.selected-brand {
  @apply border-inform-200 bg-inform-100;
  display: flex !important;
}
</style>
