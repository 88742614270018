import type {
  ATSApplicationScoringProScoring,
  ATSApplicationScoringProScoringV2,
  ATSApplicationScoringProScoringV3,
  JobApplicant,
} from '@factoryfixinc/ats-interfaces';
import { useApplicationScoreStore } from './application-score.store';
import ApplicationScorePersistence from '@/core/conversations/application-score/application-score.persistence';
import { CriteriaColor } from '@/components/Conversations/Application/ScoreChip';

export default class ApplicationScoreService {
  private readonly applicationScorePersistence = new ApplicationScorePersistence();
  private readonly applicationScoreStore = useApplicationScoreStore();

  public async fetchScoreDetails(
    jobApplicationId: JobApplicant['id'],
  ): Promise<ATSApplicationScoringProScoring | undefined> {
    return this.applicationScorePersistence.fetchScoreDetails(jobApplicationId);
  }

  public async getScoreDetail(
    jobApplicationId: JobApplicant['id'],
  ): Promise<ATSApplicationScoringProScoring | undefined> {
    const existingScoreDetails = this.applicationScoreStore.getScoreDetail(jobApplicationId);

    if (existingScoreDetails) {
      return existingScoreDetails;
    }

    const loadedScoreDetails = await this.fetchScoreDetails(jobApplicationId);
    return loadedScoreDetails;
  }

  public static formatScore(score?: number): string {
    if (score === undefined) {
      return 'N/A';
    }

    return score.toFixed(1);
  }

  public static getChipsToDisplayDictionary(): Record<
    string,
    'longevity' | 'payExpectations' | 'shiftExpectations' | 'commuteAbility' | 'longevity'
  > {
    return {
      Longevity: 'longevity',
      'Pay expectations': 'payExpectations',
      'Shift expectations': 'shiftExpectations',
      'Ability to commute': 'commuteAbility',
    };
  }

  private static getScoreChipsInfoForV2(
    score: ATSApplicationScoringProScoringV2,
  ): { title: string; text: string; color: CriteriaColor }[] {
    const assessments = score.criteriaAssessment;

    if (!assessments) {
      return [];
    }

    const colorsToEnumMap: Record<string, CriteriaColor> = {
      Red: CriteriaColor.Red,
      Yellow: CriteriaColor.Yellow,
      Green: CriteriaColor.Green,
    };

    return Object.entries(this.getChipsToDisplayDictionary()).map(([label, criteria]) => {
      // Handle the special case for longevity which maps to jobHoppingRisk in v2
      const criteriaKey = criteria === 'longevity' ? 'jobHoppingRisk' : criteria;
      const assessment = assessments[criteriaKey];

      return {
        title: label,
        text: assessment.assessment,
        color: colorsToEnumMap[assessment.color],
      };
    });
  }

  private static getScoreChipsInfoForV3(
    score: ATSApplicationScoringProScoringV3,
  ): { title: string; text: string; color: CriteriaColor }[] {
    const drilldowns = score.drilldowns;

    if (!drilldowns) {
      return [];
    }

    const statusToColorMap: Record<string, CriteriaColor> = {
      Concerning: CriteriaColor.Red,
      Uncertain: CriteriaColor.Yellow,
      Favorable: CriteriaColor.Green,
    };

    const chips = Object.entries(this.getChipsToDisplayDictionary()).map(([label, criteria]) => {
      const assessment = drilldowns[criteria];

      return {
        title: label,
        text: assessment.assessment,
        color: statusToColorMap[assessment.status],
      };
    });

    return chips;
  }

  public static getScoreChipsInfo(
    score: ATSApplicationScoringProScoring | undefined,
  ): { title: string; text: string; color: CriteriaColor }[] {
    if (!score) {
      return [];
    }

    switch (score.version) {
      case 'v2':
        return this.getScoreChipsInfoForV2(score);
      case 'v3':
        return this.getScoreChipsInfoForV3(score);
      default:
        return [];
    }
  }

  public static getScoreVersion(score: ATSApplicationScoringProScoring | undefined): string {
    const version = score?.version;

    if (!version) {
      // If the version is not available, default to v1
      return 'v1';
    }

    return version;
  }
}
