import { useAtsCandidateDiscoveryApi } from '@/composables/useApi';
import type { ProUnlockResult, ProUnlockSource } from './types/pro-unlock-source.type';
import { InternalError } from '@/core/shared/errors/internal.error';

export default class ProUnlockPersistence {
  async unlockProUserProfiles(payload: {
    employerId: number;
    userProfileIds: number[];
    source: ProUnlockSource;
  }): Promise<ProUnlockResult | undefined> {
    const { employerId, userProfileIds, source } = payload;

    const url = `/pro-unlock/employer/${employerId}`;
    const { data, error, statusCode } = await useAtsCandidateDiscoveryApi(url)
      .post({
        proUserProfileIdsToUnlock: userProfileIds,
        source,
      })
      .json<ProUnlockResult>();

    if (error.value) {
      throw new InternalError('Failed to unlock Pro', {
        error: error.value,
        status: statusCode.value,
        data: { ...data.value, employerId, userProfileIds, source },
      });
    }
    return data.value ?? undefined;
  }
}
