import { clone } from '@/utils/objects/clone.util';
import { useSearchStore } from './search.store';
import type { SearchGatedUserProfile } from './types/search-gated-user-profile.type';
import type { SelectedFilter } from './types/selected-filter.type';
import { SearchPersistence } from './search.persistence';
import type { PlacePrediction } from './types/place-prediction.type';
import type { PaginatedResponse } from './types/paginated-response.type';
import { ViewedCandidateService } from '../viewed-candidates/viewed-candidate.service';
import type { DefaultPagination } from './types/default-pagination.type';
import { defaultPagination } from './consts/default-pagination.const';
import { defaultSearchQuery } from './consts/default-seach-query.const';
import { SelectedFilterType } from './types/selected-filter-type.type';
import { type Project } from '@factoryfixinc/ats-interfaces';
import TrackingService from '@/core/shared/tracking/tracking.service';
import { TrackingActionName } from '@/core/shared/tracking/tracking-actions';

export class SearchService {
  private viewedCandidateService = new ViewedCandidateService();
  private store = useSearchStore();
  private persistence = new SearchPersistence();

  public get candidates(): SearchGatedUserProfile[] {
    return this.store.candidates;
  }

  public set candidates(candidates: SearchGatedUserProfile[]) {
    this.store.candidates = candidates;
  }

  public get serverItems(): number | undefined {
    return this.store.serverItems;
  }

  public get totalPages(): number | undefined {
    return this.store.totalPages;
  }

  public get pagination(): DefaultPagination {
    return this.store.pagination;
  }

  public set pagination(pagination: DefaultPagination) {
    this.store.pagination = pagination;
  }

  public get selectedFilters(): SelectedFilter[] {
    return this.store.selectedFilters;
  }

  public set selectedFilters(filters: SelectedFilter[]) {
    this.store.selectedFilters = filters;
  }

  public removeFilter(type: SelectedFilterType): void {
    this.store.selectedFilters = this.store.selectedFilters.filter(
      (filter) => filter.type !== type,
    );
    TrackingService.trackAction(TrackingActionName.CANDIDATE_FILTER, {
      addedFilter: null,
      removedFilter: type,
      filters: this.store.selectedFilters,
    });
  }

  public updateFilter(filter: SelectedFilter): void {
    this.store.selectedFilters = this.store.selectedFilters.map((item) =>
      item.type === filter.type ? filter : item,
    );
    TrackingService.trackAction(TrackingActionName.CANDIDATE_FILTER, {
      added_filter: filter,
      filters: this.store.selectedFilters,
      removed_filter: null,
    });
  }

  public resetPagination(): void {
    const pagination = clone(defaultPagination);

    this.store.pagination = {
      ...pagination,
      // keep user modified itemsPerPage
      itemsPerPage: this.store.pagination.itemsPerPage,
    };
  }

  public resetResults(): void {
    this.store.candidates = [];
    this.store.serverItems = undefined;
    this.store.locationSearchInput = '';
    this.resetPagination();
  }

  public resetSearch(): void {
    this.store.searchQuery = clone(defaultSearchQuery);
  }

  public setSelectedFiltersFromProject(project?: Project): void {
    this.store.selectedFilters = [];
    this.store.selectedFiltersId = undefined;

    const savedSearch = project?.savedSearches?.[0];
    const selectedFilters: SelectedFilter[] = [];

    if (!savedSearch) {
      return;
    }

    this.store.selectedFiltersId = savedSearch.id;

    if (savedSearch.query) {
      selectedFilters.push({
        type: SelectedFilterType.KEYWORD,
        value: savedSearch.query,
      });
    }

    if (savedSearch.location) {
      selectedFilters.push({
        type: SelectedFilterType.LOCATION,
        value: savedSearch.location,
      });
    }

    if (savedSearch.aroundRadius) {
      selectedFilters.push({
        type: SelectedFilterType.AROUND_RADIUS,
        value: savedSearch.aroundRadius,
      });
    }

    const brand = savedSearch.savedSearchFilters?.find((filter) => filter.type === 'BRAND');

    if (brand) {
      selectedFilters.push({
        type: SelectedFilterType.BRAND,
        value: brand.value,
      });
    }

    const jobTitle = savedSearch.savedSearchFilters?.find((filter) => filter.type === 'JOB_TITLE');

    if (jobTitle) {
      selectedFilters.push({
        type: SelectedFilterType.JOB_TITLE,
        value: jobTitle.value,
      });
    }

    const knowledgeDiscipline = savedSearch.savedSearchFilters?.find(
      (filter) => filter.type === 'KNOWLEDGE_DISCIPLINE',
    );

    if (knowledgeDiscipline) {
      selectedFilters.push({
        type: SelectedFilterType.KNOWLEDGE_DISCIPLINE,
        value: knowledgeDiscipline.value,
      });
    }

    const lastActive = savedSearch.savedSearchFilters?.find(
      (filter) => filter.type === 'LAST_ACTIVE',
    );

    if (lastActive) {
      selectedFilters.push({
        type: SelectedFilterType.LAST_ACTIVE,
        value: Number(lastActive.value),
      });
    }

    const technology = savedSearch.savedSearchFilters?.find(
      (filter) => filter.type === 'TECHNOLOGY',
    );

    if (technology) {
      selectedFilters.push({
        type: SelectedFilterType.TECHNOLOGY,
        value: technology.value,
      });
    }

    const yearsOfExperience =
      savedSearch.savedSearchFilters?.filter((filter) => filter.type === 'YEARS_OF_EXPERIENCE') ??
      [];

    if (yearsOfExperience.length) {
      selectedFilters.push({
        type: SelectedFilterType.YEARS_OF_EXPERIENCE,
        value: yearsOfExperience.map((filter) => Number(filter.value)),
      });
    }

    this.store.selectedFilters = selectedFilters;
  }

  public async getAddressPredictions(address: string): Promise<PlacePrediction[]> {
    return this.persistence.getAddressPredictions(address);
  }

  public async searchCandidates(): Promise<PaginatedResponse<SearchGatedUserProfile>> {
    const result = await this.persistence.searchCandidates();

    const candidates = result.hits;
    this.store.candidates = candidates;
    this.store.serverItems = result.nbHits;
    this.store.totalPages = result.nbPages;

    await this.viewedCandidateService.fetchViewedCandidates();

    return result;
  }
}
