<script setup lang="ts">
import SpinnerLoader from '@/components/Shared/Loaders/SpinnerLoader.vue';
import FFSourcing from '@/assets/png/sourcing/ff-sourcing.png';
import { computed, onMounted, ref, watch } from 'vue';
import TrackingService from '@/core/shared/tracking/tracking.service';
import { TrackingActionName } from '@/core/shared/tracking/tracking-actions';
import { DialogService } from '@/core/shared/dialog/dialog.service';
import ProjectService from '@/core/shared/project/project.service';
import { useRouter } from 'vue-router';
import { DrawerService } from '@/core/sourcing/drawer/drawer.service';

const router = useRouter();
const drawerService = new DrawerService();
const dialogService = new DialogService();
const projectService = new ProjectService();

const hasSearchedProjects = ref<boolean>(false);

const isSearchingProjects = computed(() => {
  return projectService.isSearchingProjects;
});

const hasProjects = computed(() => {
  return projectService.projects.length > 0;
});

const isDisplayingLoader = computed(() => {
  return (
    isSearchingProjects.value ||
    (!hasProjects.value && !hasSearchedProjects.value) ||
    (hasProjects.value && hasSearchedProjects.value)
  );
});

function openCreateJob() {
  dialogService.isCreateProjectDialogOpen = true;
  TrackingService.trackAction(TrackingActionName.JOB_STARTED);
}

async function handleCurrentProject() {
  if (!projectService.currentProject?.id) {
    return;
  }

  await router.replace({
    name: 'sourcing-job-id',
    params: { id: `${projectService.currentProject.id}` },
  });
}

function displayProjectDrawer() {
  drawerService.projectsDrawer = true;
}

watch(
  isSearchingProjects,
  (isSearching) => {
    if (!isSearching) {
      hasSearchedProjects.value = true;
    }
  },
  { immediate: true },
);

onMounted(() => {
  displayProjectDrawer();
  handleCurrentProject();
});
</script>

<template>
  <div v-if="!isDisplayingLoader" class="flex h-screen w-full flex-col items-center justify-center">
    <img :src="FFSourcing" alt="New Job Banner" class="mb-2 block" width="360" height="360" />
    <p class="mb-2 font-serif text-2xl font-normal leading-7">
      Manufacturing talent at your fingertips
    </p>
    <p class="font-sans font-normal">
      Our talent pool has <span class="font-bold">2M+ manufacturing professionals</span>.
    </p>
    <p class="font-sans font-normal">Find promising candidates, review their experience,</p>
    <p class="mb-6 font-sans font-normal">and message them directly.</p>
    <button
      class="flex items-center justify-center rounded bg-highlight-500 px-4 py-2.5 text-sm font-bold leading-[18px] text-white"
      @click="openCreateJob"
    >
      Search candidates
    </button>
  </div>

  <div v-show="isDisplayingLoader" class="flex min-h-screen items-center justify-center">
    <SpinnerLoader class="h-8 w-8" />
  </div>
</template>
