export type Id = 'system' | 'anonymous' | number;
export enum EventType {
  VisitedPage = 'visited-page',
  UserIdentity = 'user-identity',
  UserGroup = 'user-group',
  Action = 'action',
}
export interface TrackingVisitedPageInput {
  app: string;
  name: string;
  title: string;
  url: string;
  path: string;
  search: string;
}

export interface TrackingActionDataInput {
  actionName: string;
  properties: Record<string, unknown>;
}

export interface TrackingEmployerUserIdentityInput {
  name?: string;
  firstName?: string;
  lastName?: string;
  companyName?: string;
  companyId?: number;
  createdAt?: Date;
  email?: string;
  phone?: string;
  userType?: string;
  employerRole?: string[];
  jobTitle?: string;
  userPreferredFlow?: boolean;
  sessionNumber?: number;
  totalCopilotsOwner?: number;
  totalCopilotsWatcher?: number;
  totalProjectsOwner?: number;
  totalProjectsWatcher?: number;
}

interface TrackingAddressInput {
  street?: string;
  city?: string;
  state?: string;
  country?: string;
  postalCode?: string;
}
export interface TrackingCompanyUserGroupInput {
  groupId: number;
  companyName: string;
  companyId: number;
  employees?: number;
  address?: TrackingAddressInput;
  accountCreatedAt?: string;
  subscriptionPlan?: string;
  subscriptionStatus?: string[];
  contractStart?: string;
  contractRenewal?: string;
  trialStart?: string;
  trialEnd?: string;
  paymentStatus?: string;
  website?: string;
  zipCode?: number;
  phone?: string;
  industry?: string[];
  copilotsAvailable?: number;
  copilotsActive?: number;
  copilotsPlan?: number;
  projectsTotal?: number;
  projectsArchived?: number;
  projectsUnarchived?: number;
  projectsUnarchivedUnassigned?: number;
  projectsUnarchivedAssigned?: number;
  tscreditsAllocation?: number;
  tscreditsUsed?: number;
  applicants?: number;
  engagedApplicants?: number;
}
export interface TrackingEventInput {
  userId: Id;
  type: EventType;
  data:
    | TrackingVisitedPageInput
    | TrackingEmployerUserIdentityInput
    | TrackingCompanyUserGroupInput
    | TrackingActionDataInput;
}

export enum PageSource {
  Sourcing = 'Sourcing',
  Conversations = 'Conversations',
  Search = 'Search',
}
