import { type EmployerFeature, FeatureName, FeatureStatus } from '@factoryfixinc/ats-interfaces';
import { EmployerFeaturePersistence } from './employer-feature.persistence';
import { useEmployerFeatureStore } from './employer-feature.store';

export class EmployerFeatureService {
  private persistence = new EmployerFeaturePersistence();
  private store = useEmployerFeatureStore();

  public get hasSubscriptionV2Enabled(): boolean {
    return this.store.employerFeatures.some(
      (feature) =>
        feature.featureName === FeatureName.SUBSCRIPTION_V2 &&
        feature.featureStatus === FeatureStatus.ENABLED,
    );
  }

  public get hasUrlScreeningEnabled(): boolean {
    return this.store.employerFeatures.some(
      (feature) =>
        feature.featureName === FeatureName.URL_SCREENING &&
        feature.featureStatus === FeatureStatus.ENABLED,
    );
  }

  public get hasZeroScreeningEnabled(): boolean {
    return this.store.employerFeatures.some(
      (feature) =>
        feature.featureName === FeatureName.ZERO_SCREENING &&
        feature.featureStatus === FeatureStatus.ENABLED,
    );
  }

  public get hasScheduledUpdatesEnabled(): boolean {
    const existingFlag = this.store.employerFeatures.find(
      (feature) => feature.featureName === FeatureName.SCHEDULED_UPDATES,
    );
    if (existingFlag) {
      return existingFlag.featureStatus === FeatureStatus.ENABLED;
    }
    return true;
  }

  public get hasProSearchEnabled(): boolean {
    const existingFlag = this.store.employerFeatures.find(
      (feature) => feature.featureName === FeatureName.PRO_SEARCH,
    );
    if (existingFlag) {
      return existingFlag.featureStatus === FeatureStatus.ENABLED;
    }
    return false;
  }

  public async fetchEmployerFeatures(): Promise<EmployerFeature[]> {
    const employerFeatures = await this.persistence.fetchEmployerFeatures();
    this.store.employerFeatures = employerFeatures;

    return employerFeatures;
  }
}
