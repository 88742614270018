import type { ATSApplicationScoringProScoring } from '@factoryfixinc/ats-interfaces';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useApplicationScoreStore = defineStore(
  'applicationScore',
  () => {
    const scores = ref(new Map<number, ATSApplicationScoringProScoring>());

    function setScoreDetail(proScore: ATSApplicationScoringProScoring) {
      if (!proScore.jobApplicationId) {
        throw new Error('jobApplicationId is required');
      }

      scores.value.set(proScore.jobApplicationId, proScore);
    }

    function getScoreDetail(applicationId: number) {
      return scores.value.get(applicationId);
    }

    return {
      setScoreDetail,
      getScoreDetail,
    };
  },
  {
    persist: true,
  },
);
