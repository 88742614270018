import { useAtsConversationApi, useAtsConversationApiWithOptions } from '@/composables/useApi';
import type { Employer, Job, UserProfile } from '@factoryfixinc/ats-interfaces';
import { HttpStatus } from '@/core/shared/errors/enums/http-status.enum';
import { UserOptedOutOfMessagingError } from '@/core/shared/errors/user-opted-out-of-messaging.error';
import { UserAlreadyAppliedError } from '@/core/shared/errors/user-already-applied.error';
import { InternalError } from '@/core/shared/errors/internal.error';

export default class MessagingPersistence {
  public async createMessage(
    employerId: Employer['id'],
    userProfile: Pick<UserProfile, 'id' | 'nameFirst' | 'nameLast'>,
    jobId: Job['id'],
    message: string,
  ): Promise<{ conversationId: number }> {
    const url = `message/employer/${employerId}/pro/${userProfile.id}/job/${jobId}?source=talent_search`;

    const { data, error } = await useAtsConversationApiWithOptions({ parseError: true })(url)
      .post({
        message,
      })
      .json<{ conversationId: number }>();

    if (error.value) {
      if (error.value.includes('User cannot receive messages')) {
        throw new UserOptedOutOfMessagingError(userProfile);
      }

      if (error.value.includes('already applied to job with id:')) {
        throw new UserAlreadyAppliedError(userProfile, jobId);
      }

      throw new InternalError('Something went wrong.', { data: { userProfile, jobId } });
    }

    if (!data.value) {
      throw new InternalError('Message not sent. Please try again.', {
        data: { userProfile, jobId },
      });
    }

    return data.value;
  }

  public async getConversation(
    employerId: Employer['id'],
    proUserProfileId: UserProfile['id'],
    jobId: Job['id'],
  ): Promise<{ conversationId: number } | undefined> {
    const url = `conversation/employer/${employerId}/pro/${proUserProfileId}/job/${jobId}`;
    const { data, error, statusCode } = await useAtsConversationApi(url).get().json();

    if (statusCode.value === HttpStatus.NOT_FOUND) {
      return;
    }

    if (error.value) {
      throw new Error(error.value);
    }

    return data.value;
  }
}
