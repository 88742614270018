import { useAtsApplicationScoringApi } from '@/composables/useApi';
import type { ATSApplicationScoringProScoring, JobApplicant } from '@factoryfixinc/ats-interfaces';
import { InternalError } from '@/core/shared/errors/internal.error';
import { HttpStatus } from '@/core/shared/errors/enums/http-status.enum';
import { useApplicationScoreStore } from '@/core/conversations/application-score/application-score.store';

export default class ApplicationScorePersistence {
  private applicationScoreStore = useApplicationScoreStore();

  public async fetchScoreDetails(
    jobApplicationId: JobApplicant['id'],
  ): Promise<ATSApplicationScoringProScoring | undefined> {
    const url = `/score?jobApplicationId=${jobApplicationId}`;
    const { data, error, statusCode } = await useAtsApplicationScoringApi(url)
      .get()
      .json<ATSApplicationScoringProScoring>();

    if (statusCode.value === HttpStatus.NOT_FOUND || !data.value) {
      return;
    }

    if (error.value) {
      throw new InternalError('Failed to fetch score details', {
        error: error.value,
        status: statusCode.value,
        data: { jobApplicationId },
      });
    }

    this.applicationScoreStore.setScoreDetail(data.value);
    return data.value;
  }
}
