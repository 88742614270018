<script setup lang="ts">
/* eslint-disable max-lines */
import { computed, onMounted, ref, watch } from 'vue';
import ApplicationScoreCard from '@/components/Conversations/Application/ApplicationScoreCard.vue';
import ApplicationStatusPicker from '@/components/Conversations/Application/ApplicationStatusPicker.vue';
import CollapsiblePanel from '@/components/Shared/CollapsiblePanel/CollapsiblePanel.vue';
import ProProfileService from '@/core/conversations/pro-profile/pro-profile.service';
import ApplicationScoreService from '@/core/conversations/application-score/application-score.service';
import ApplicationCopilotBanner from '@/components/Conversations/Application/ApplicationCopilotBanner.vue';
import type { JobApplicantWithJobTitle } from '@/core/shared/job/job.type';
import DateUtils from '@/utils/date-utils';
import {
  type ATSApplicationScoringProScoring,
  JobApplicantStatus,
  type ProTaxonomyMachineMapRelations,
} from '@factoryfixinc/ats-interfaces';
import TaxonomyService from '@/core/shared/taxonomy/taxonomy.service';
import type { ProUserProfileWithRelations } from '@/core/conversations/pro-profile/pro-profile';
import { VChip } from 'vuetify/components';
import ApplicationScoreHoverWrapper from '@/components/Conversations/Application/ApplicationScoreHoverWrapper.vue';
import TrackingService from '@/core/shared/tracking/tracking.service';
import { TrackingActionName } from '@/core/shared/tracking/tracking-actions';
import { getUserProfileSortedWorkExperiences } from '@/core/shared/user-profile/utils';
import ProjectService from '@/core/shared/project/project.service';
import { JobUtil } from '@/utils/job.util';
import ConversationIndexService from '@/core/conversations/conversation-index/conversation-index.service';
import ProNotesService from '@/core/shared/pro-notes/pro-notes.service';
import CopilotScoreBlurb from '@/components/Conversations/Copilot/CopilotScoreBlurb.vue';
import { getJobTitle } from '@/core/sourcing/utils/job';
import PlusSvg from '@/assets/svg/plus-16.svg?component';
import MeService from '@/core/shared/me/me.service';
import { DialogService } from '@/core/shared/dialog/dialog.service';
import { ErrorService } from '@/core/shared/errors/error.service';
import SpinnerLoader from '@/components/Shared/Loaders/SpinnerLoader.vue';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';

const dialogService = new DialogService();
const meService = new MeService();
const proProfileService = new ProProfileService();
const applicationScoreService = new ApplicationScoreService();
const taxonomyService = new TaxonomyService();
const projectService = new ProjectService();
const conversationIndexService = new ConversationIndexService();
const proNotesService = new ProNotesService();

const scoreDetails = ref<ATSApplicationScoringProScoring>();
const isLoadingProfile = ref<boolean>(false);

const proProfile = computed(() => proProfileService.selectedProProfile);
const workExperience = computed(() =>
  getUserProfileSortedWorkExperiences(proProfile.value?.proHistoryEmployers ?? []),
);
const selectedApplication = computed(() => proProfileService.selectedProApplication);
const initiatedByOutreach = computed(() => proProfileService.initiatedByOutreach);
const computedSkills = computed(() => getSkills(proProfile.value));

const chips = computed(() => ApplicationScoreService.getScoreChipsInfo(scoreDetails.value));
const formattedScore = computed(() =>
  ApplicationScoreService.formatScore(scoreDetails.value?.score),
);

const applications = computed(() => {
  if (!proProfile.value?.jobApplicants) {
    return [];
  }

  // sort by date of creation descending
  return [...proProfile.value.jobApplicants].sort(
    (a, b) => new Date(b.createTs).getTime() - new Date(a.createTs).getTime(),
  );
});

const addProToJobModalProps = computed({
  get: () => dialogService.addProToJobModalProps,
  set: (props) => (dialogService.addProToJobModalProps = props),
});

const jobTitle = computed(() => {
  if (!selectedApplication.value) {
    return '';
  }

  return getJobTitle(selectedApplication.value.job, false).displayTitle;
});

const handleApplicationChange = async (application?: JobApplicantWithJobTitle) => {
  if (application) {
    scoreDetails.value = await applicationScoreService.getScoreDetail(application.id);
  }
};

function getWorkExperienceTime(startDateMonth?: string, endDateMonth?: string) {
  const startDate = DateUtils.yearMonthToDate(startDateMonth);
  const endDate = DateUtils.yearMonthToDate(endDateMonth);

  return {
    startDate: startDate ? DateUtils.formatTimestamp(startDate, 'MMM YYYY') : '',
    endDate: endDate ? DateUtils.formatTimestamp(endDate, 'MMM YYYY') : 'Present',
    duration: startDate && endDate ? DateUtils.getHumanizedDuration(startDate, endDate) : '',
  };
}

function getSkills(profile?: ProUserProfileWithRelations | null) {
  if (!profile) return;

  const findById = (id: number) => (candidate: { id: number }) => candidate.id === id;

  const { industries, knowledgeDisciplines, machines, brands } = taxonomyService;
  const { pro } = profile;

  return {
    industries:
      pro?.proTaxonomyIndustryMaps?.map((proIndustry) =>
        industries.find(findById(proIndustry.taxonomyIndustryId)),
      ) ?? [],
    knowledgeDisciplines:
      pro?.proTaxonomyKnowledgeDisciplineMaps?.map((discipline) =>
        knowledgeDisciplines.find(findById(discipline.taxonomyKnowledgeDisciplineId)),
      ) ?? [],
    machines:
      pro?.proTaxonomyMachineMaps
        ?.map((proMachine) => {
          const machine = machines.find(findById(proMachine.taxonomyMachineId));
          return {
            machine,
            brands: proMachine,
          };
        })
        ?.map((mappedMachine) => {
          return {
            ...mappedMachine.machine,
            brands: (
              mappedMachine.brands as ProTaxonomyMachineMapRelations
            ).proTaxonomyMachineMapTaxonomyBrandMaps?.map((proBrand) =>
              brands.find(findById(proBrand.taxonomyBrandId)),
            ),
          };
        }) ?? [],
  };
}

async function updateApplicationStatus(
  applicationId: JobApplicantWithJobTitle['id'],
  newStatus: JobApplicantStatus,
  previousStatus: JobApplicantStatus,
  proId: number,
  reason: string,
  isSilent: boolean,
) {
  if (!applicationId || !newStatus) {
    return;
  }
  if (applicationId) {
    await proProfileService.changeApplicationStatus(applicationId, newStatus, reason, isSilent);

    if (newStatus === JobApplicantStatus.REJECTED && reason) {
      await proNotesService.createProRejectedNote(proId, reason);
    }

    // Refreshes count on the projects panel
    projectService.updateLocalProjectJobApplicationStatusCount({
      jobId: Number(proProfileService.selectedProApplication?.jobId),
      newStatus,
      previousStatus,
    });
    // Refresh the conversations in case the status of the current application is changed
    conversationIndexService.filterLocalConversationIndexesByStatus(applicationId, newStatus);
  }
  TrackingService.trackAction(TrackingActionName.APPLICATION_STATUS_CHANGED, {
    source: 'Conversation Top Banner',
    from_status: previousStatus,
    to_status: newStatus,
  });
}

const getProjectTitle = (jobId: number, applicationId: number) => {
  const application = applications.value?.find((app) => app.id === applicationId);
  const jobTitle =
    projectService.projectTitlesByJobId.get(jobId) ||
    application?.job?.displayTitle ||
    application?.job?.jobTitle?.title ||
    '';
  return jobTitle;
};

const getPayRange = (jobId: number) => {
  const job = applications.value?.find((app) => app.job?.id === jobId)?.job;
  return job ? JobUtil.getPayRange(job) : '';
};

const openAddProToJobModal = () => {
  if (!proProfile.value) {
    SnackbarService.critical('Please, choose a candidate first');
    return;
  }

  addProToJobModalProps.value = {
    isOpen: true,
    pros: [proProfile.value],
  };
};

onMounted(async () => {
  if (selectedApplication.value) {
    await handleApplicationChange(selectedApplication.value);
  }
});

watch(addProToJobModalProps, async (props) => {
  if (props.isOpen === true) {
    return;
  }

  const employerId = meService.employer?.id;
  const userProfileId = proProfile.value?.id;

  if (!employerId || !userProfileId) {
    return;
  }

  try {
    isLoadingProfile.value = true;
    await proProfileService.getProProfile(employerId, userProfileId);
  } catch (error) {
    ErrorService.captureException(error);
  } finally {
    isLoadingProfile.value = false;
  }
});

watch(
  () => selectedApplication.value,
  (application) => {
    handleApplicationChange(application ?? undefined);
  },
);
</script>

<template>
  <div class="flex h-full flex-col flex-wrap items-center" v-if="selectedApplication">
    <div class="mt-4 w-full px-4" v-if="initiatedByOutreach">
      <ApplicationCopilotBanner />
    </div>
    <div class="flex w-full flex-[3] flex-col gap-4 overflow-auto p-4">
      <CopilotScoreBlurb
        v-if="scoreDetails?.score && scoreDetails.summary && scoreDetails.jobApplicationId"
        :show-visible-only-to-you="false"
        :show-copilot-chip="false"
        :score="formattedScore"
        :content="scoreDetails.summary"
        :chips="chips"
        :application-id="scoreDetails.jobApplicationId"
        :score-version="scoreDetails.version"
        :job-title="jobTitle"
      />

      <div class="flex flex-row gap-4" v-if="selectedApplication">
        <div class="flex flex-1 flex-col gap-4">
          <CollapsiblePanel>
            <template #header>
              <span class="flex flex-row">
                <span class="flex-1 font-bold">Work Experience</span>
                <span class="font-xs 12 font-normal text-tint-400">{{
                  workExperience.length
                }}</span>
              </span>
            </template>
            <template #body>
              <div class="flex flex-col gap-2">
                <div v-if="!workExperience.length" class="text-sm text-shade-800">
                  No work experience added
                </div>
                <div
                  v-else
                  class="flex flex-col text-sm"
                  v-for="{ startYYYYMM, endYYYYMM, id, jobTitle, name } of workExperience"
                  :key="id"
                >
                  <div class="text-black">{{ jobTitle }}</div>
                  <div class="text-shade-800">{{ name }}</div>
                  <div class="text-shade-800">
                    <span>
                      {{ getWorkExperienceTime(startYYYYMM, endYYYYMM).startDate }}
                      -
                      {{ getWorkExperienceTime(startYYYYMM, endYYYYMM).endDate }}
                    </span>
                    <template v-if="getWorkExperienceTime(startYYYYMM, endYYYYMM).duration">
                      &bullet;
                      <span>{{ getWorkExperienceTime(startYYYYMM, endYYYYMM).duration }}</span>
                    </template>
                  </div>
                </div>
              </div>
            </template>
          </CollapsiblePanel>

          <CollapsiblePanel>
            <template #header>
              <span class="flex flex-row">
                <span class="flex-1 font-bold">Education & Certification</span>
                <span class="font-xs 12 font-normal text-tint-400">{{
                  proProfile?.proEducationCertifications?.length
                }}</span>
              </span>
            </template>
            <template #body>
              <div class="flex flex-col gap-2">
                <div
                  v-if="!proProfile?.proEducationCertifications?.length"
                  class="text-sm text-shade-800"
                >
                  No education history added
                </div>
                <div
                  v-else
                  class="flex flex-col text-sm"
                  v-for="{
                    certificateTitle,
                    certificateIssuer,
                    dateIssued,
                    id,
                  } of proProfile?.proEducationCertifications"
                  :key="id"
                >
                  <div class="text-black">{{ certificateTitle }}</div>
                  <div class="text-shade-800">{{ certificateIssuer }}</div>
                  <div class="text-shade-800" v-if="dateIssued">
                    <span> {{ DateUtils.formatTimestamp(dateIssued, 'MMM YYYY') }}</span>
                  </div>
                </div>
              </div>
            </template>
          </CollapsiblePanel>

          <CollapsiblePanel header="Skills & Technologies">
            <template #body>
              <div class="flex flex-col gap-2">
                <div
                  v-if="
                    !computedSkills?.industries.length &&
                    !computedSkills?.knowledgeDisciplines.length &&
                    !computedSkills?.machines.length
                  "
                  class="text-sm text-shade-800"
                >
                  No skills added
                </div>
                <div v-if="computedSkills?.industries.length">
                  <div class="text-sm font-semibold">Industries</div>
                  <div class="text-sm text-tint-400">
                    <v-chip
                      v-for="proIndustry of computedSkills?.industries ?? []"
                      :key="proIndustry?.id"
                      :text="proIndustry?.title"
                      size="small"
                      class="m-1"
                    ></v-chip>
                  </div>
                </div>

                <div v-if="computedSkills?.knowledgeDisciplines.length">
                  <div class="text-sm font-semibold">Knowledge Disciplines</div>
                  <div class="text-sm text-tint-400">
                    <v-chip
                      v-for="discipline of computedSkills?.knowledgeDisciplines ?? []"
                      :key="discipline?.id"
                      :text="discipline?.title"
                      size="small"
                      class="m-1"
                    ></v-chip>
                  </div>
                </div>
                <div v-if="computedSkills?.machines.length">
                  <div class="text-sm font-semibold">Machines / Technologies</div>
                  <div class="text-sm text-black">
                    <ul class="">
                      <li v-for="machine of computedSkills?.machines ?? []" :key="machine.id">
                        {{ machine?.title }}
                        <ul v-if="machine?.brands" class="ml-2 text-tint-400">
                          <li v-for="brand of machine?.brands" :key="brand?.id">
                            {{ brand?.title }}
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
          </CollapsiblePanel>
          <CollapsiblePanel>
            <template #header>
              <span class="flex flex-row">
                <span class="flex-1 font-bold">References</span>
                <span class="font-xs 12 font-normal text-tint-400">{{
                  proProfile?.proReferences?.length
                }}</span>
              </span>
            </template>
            <template #body>
              <div class="flex flex-col gap-2">
                <div v-if="!proProfile?.proReferences" class="text-sm text-shade-800">
                  No references added
                </div>
                <div
                  v-else
                  class="flex flex-col text-sm"
                  v-for="{
                    nameFirst,
                    nameLast,
                    createTs,
                    referReason,
                    id,
                  } of proProfile?.proReferences"
                  :key="id"
                >
                  <div class="text-black">{{ nameFirst }} {{ nameLast }}</div>
                  <div class="text-shade-800">
                    {{ DateUtils.formatTimestamp(createTs, 'MMM YYYY') }}
                  </div>
                  <div class="text-shade-800">{{ referReason }}</div>
                </div>
              </div>
            </template>
          </CollapsiblePanel>
        </div>
        <div class="flex flex-1 flex-col gap-4">
          <CollapsiblePanel>
            <template #header>
              <span class="flex flex-row">
                <span class="flex-1 font-bold">Applications</span>
                <span class="font-xs 12 font-normal text-tint-400">{{ applications?.length }}</span>
              </span>
            </template>
            <template #body>
              <div class="flex flex-col gap-3">
                <!-- Add to another job button -->
                <div class="flex h-14 items-center rounded-md bg-white p-3">
                  <div v-if="isLoadingProfile" class="flex w-full items-center justify-center">
                    <SpinnerLoader />
                  </div>

                  <button
                    v-else
                    class="flex items-center gap-2 font-bold text-highlight-500"
                    @click="openAddProToJobModal"
                  >
                    <PlusSvg />
                    <span class="text-sm">Add candidate to another job</span>
                  </button>
                </div>

                <!-- Applications list -->
                <div
                  class="rounded-md bg-white p-3"
                  v-for="{
                    job,
                    jobId,
                    id,
                    status,
                    score,
                    applyTs,
                    initiatedByOutreach,
                  } in applications"
                  :key="id"
                >
                  <div class="text-sm font-bold">{{ getProjectTitle(job?.id as number, id) }}</div>
                  <div class="text-sm text-shade-800">
                    <span>{{ JobUtil.getShift(job) }}</span>
                    <span> &bullet; {{ getPayRange(job?.id as number) }}</span>
                    <span> &bullet; #{{ jobId }}</span>
                  </div>
                  <div class="text-sm text-shade-800">
                    Applied: {{ applyTs ? DateUtils.timeAgo(applyTs) : 'N/A' }}
                  </div>
                  <div class="flex flex-row">
                    <div class="min-w-[60px] flex-1">
                      <ApplicationScoreHoverWrapper
                        v-if="job?.id"
                        :application-id="id"
                        :fallback-score="score"
                        :initiated-by-outreach="initiatedByOutreach"
                        :job-id="job?.id"
                        :job-title="getProjectTitle(job?.id as number, id) || ''"
                        popup-location="top center"
                        :load-on-mount="true"
                        analyticsSource="Applications Tab"
                      >
                        <template #hoverTrigger="{ scoreDetails, scoreIsLoading }">
                          <v-skeleton-loader type="list-item" v-if="scoreIsLoading" />
                          <ApplicationScoreCard
                            v-else
                            :is-selected="false"
                            :score="scoreDetails?.score"
                            :should-bg-be-white="true"
                          />
                        </template>
                      </ApplicationScoreHoverWrapper>
                    </div>
                    <ApplicationStatusPicker
                      v-if="id"
                      :status="status"
                      @update-value="
                        ({ newStatus, previousStatus, reason, isSilent }) =>
                          updateApplicationStatus(
                            id,
                            newStatus,
                            previousStatus,
                            proProfile?.pro.id as number,
                            reason,
                            isSilent,
                          )
                      "
                    >
                    </ApplicationStatusPicker>
                  </div>
                </div>
              </div>
            </template>
          </CollapsiblePanel>
        </div>
      </div>
    </div>
  </div>
</template>
