import { SearchPersistence } from '@/core/shared/search/search.persistence';
import type { PlacePrediction } from '@/core/shared/search/types/place-prediction.type';
import { useSearchStore } from './search.store';
import { SelectedFilterType } from '@/core/sourcing/search/types/selected-filter-type.type';
import type { SelectedFilter } from '@/core/sourcing/search/types/selected-filter.type';
import type { SearchService as SourcingSearchService } from '@/core/sourcing/search/search.service';

export class SearchService {
  private persistence = new SearchPersistence();
  private store = useSearchStore();
  public async getAddressPredictions(address: string): Promise<PlacePrediction[]> {
    return this.persistence.getAddressPredictions(address);
  }

  public get keyword(): string | undefined {
    return this.store.keyword;
  }

  public set keyword(keyword: string | undefined) {
    this.store.keyword = keyword;
  }

  public get jobTitle(): string | undefined {
    return this.store.jobTitle;
  }

  public set jobTitle(jobTitle: string | undefined) {
    this.store.jobTitle = jobTitle;
  }

  public get skills(): string | undefined {
    return this.store.skills;
  }

  public set skills(skills: string | undefined) {
    this.store.skills = skills;
  }

  public get machines(): string | undefined {
    return this.store.machines;
  }

  public set machines(machines: string | undefined) {
    this.store.machines = machines;
  }

  public get brands(): string | undefined {
    return this.store.brands;
  }

  public set brands(brands: string | undefined) {
    this.store.brands = brands;
  }

  public get lastActive(): number | undefined {
    return this.store.lastActive;
  }

  public set lastActive(lastActive: number | undefined) {
    this.store.lastActive = lastActive;
  }

  public get location(): string | undefined {
    return this.store.location;
  }

  public set location(location: string | undefined) {
    this.store.location = location;
  }

  public get distance(): number | undefined {
    return this.store.distance;
  }

  public set distance(distance: number | undefined) {
    this.store.distance = distance;
  }

  public get isSearchingCandidates(): boolean {
    return this.store.isSearchingCandidates;
  }

  public set isSearchingCandidates(isSearchingCandidates: boolean) {
    this.store.isSearchingCandidates = isSearchingCandidates;
  }

  /**
   * Syncs the current filters to the sourcing search service
   * @param searchService The sourcing search service instance to sync filters to
   */
  public syncFiltersToSourcingSearch(searchService: SourcingSearchService): void {
    // Clear existing filters
    searchService.selectedFilters = [];

    // Add filters with proper typing for each type
    if (this.keyword) {
      const filter: SelectedFilter = {
        type: SelectedFilterType.KEYWORD,
        value: this.keyword,
      };
      searchService.selectedFilters.push(filter);
    }

    if (this.jobTitle) {
      const filter: SelectedFilter = {
        type: SelectedFilterType.JOB_TITLE,
        value: this.jobTitle,
      };
      searchService.selectedFilters.push(filter);
    }

    if (this.skills) {
      const filter: SelectedFilter = {
        type: SelectedFilterType.KNOWLEDGE_DISCIPLINE,
        value: this.skills,
      };
      searchService.selectedFilters.push(filter);
    }

    if (this.machines) {
      const filter: SelectedFilter = {
        type: SelectedFilterType.TECHNOLOGY,
        value: this.machines,
      };
      searchService.selectedFilters.push(filter);
    }

    if (this.brands) {
      const filter: SelectedFilter = {
        type: SelectedFilterType.BRAND,
        value: this.brands,
      };
      searchService.selectedFilters.push(filter);
    }

    if (this.lastActive) {
      const filter: SelectedFilter = {
        type: SelectedFilterType.LAST_ACTIVE,
        value: this.lastActive,
      };
      searchService.selectedFilters.push(filter);
    }

    if (this.location) {
      const filter: SelectedFilter = {
        type: SelectedFilterType.LOCATION,
        value: this.location,
      };
      searchService.selectedFilters.push(filter);
    }

    if (this.distance) {
      const filter: SelectedFilter = {
        type: SelectedFilterType.AROUND_RADIUS,
        value: this.distance,
      };
      searchService.selectedFilters.push(filter);
    }
  }

  /**
   * Checks if there are any filters set
   */
  public hasFilters(): boolean {
    return !!(
      this.keyword ||
      this.jobTitle ||
      this.skills ||
      this.machines ||
      this.brands ||
      this.lastActive ||
      this.location
    );
  }
}
