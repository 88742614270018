import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useSearchStore = defineStore(
  'sharedSearch',
  () => {
    // Loading
    const isSearchingCandidates = ref<boolean>(false);

    // Data
    const keyword = ref<string | undefined>(undefined);
    const jobTitle = ref<string | undefined>(undefined);
    const skills = ref<string | undefined>(undefined);
    const machines = ref<string | undefined>(undefined);
    const brands = ref<string | undefined>(undefined);
    const lastActive = ref<number | undefined>(undefined);
    const location = ref<string | undefined>(undefined);
    const distance = ref<number | undefined>(undefined);

    return {
      isSearchingCandidates,
      brands,
      jobTitle,
      lastActive,
      keyword,
      machines,
      skills,
      location,
      distance,
    };
  },
  {
    persist: true,
  },
);
