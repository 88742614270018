<script setup lang="ts">
import { computed } from 'vue';
import ScoreRating from '@/components/Conversations/Application/ScoreRating.vue';
import ScoreChip from '@/components/Conversations/Application/ScoreChip.vue';
import ApplicationScoreService from '@/core/conversations/application-score/application-score.service';
import ProProfileService from '@/core/conversations/pro-profile/pro-profile.service';
import CopilotScoreFeedback from '@/components/Conversations/Copilot/CopilotScoreFeedback.vue';
import MeService from '@/core/shared/me/me.service';
import type { ATSApplicationScoringProScoring } from '@factoryfixinc/ats-interfaces';

const props = withDefaults(
  defineProps<{
    scoreDetails: ATSApplicationScoringProScoring | undefined;
    jobTitle: string;
    jobId?: number;
    isLoading: boolean;
    analyticsSource: string;
    applicationId: number;
  }>(),
  {
    isLoading: true,
  },
);

const meService = new MeService();
const userProfileId = computed(() => meService.userProfile?.id);
const proProfileService = new ProProfileService();
const numericScore = computed(() => props.scoreDetails?.score || 0);
const normalizedScore = computed(() => ApplicationScoreService.formatScore(numericScore.value));
const isLegacyScore = computed(() => !!normalizedScore.value && !props.scoreDetails?.summary);
const isScoreMissing = computed(
  () => (numericScore.value === 0 || !normalizedScore.value) && !props.scoreDetails?.summary,
);
const scoreVersion = computed(() => ApplicationScoreService.getScoreVersion(props.scoreDetails));
const chips = computed(() => ApplicationScoreService.getScoreChipsInfo(props.scoreDetails));
const initiatedByOutreach = computed(() => proProfileService.initiatedByOutreach);

const summaryText = computed(() => {
  if (isLegacyScore.value) {
    return 'Summary unavailable. This candidate applied before the release of the summary tool.';
  }
  if (isScoreMissing.value) {
    // eslint-disable-next-line max-len
    return 'We were unable to get the score for this candidate. If this is happening consistently please reach out to support.';
  }
  return props.scoreDetails?.summary || '';
});
</script>

<template>
  <div class="w-[496px] rounded-lg bg-white p-6 shadow-md">
    <template v-if="isLoading">
      <v-skeleton-loader boilerplate type="subtitle, paragraph" />
    </template>
    <template v-else>
      <div class="flex flex-col space-y-2" v-if="initiatedByOutreach">
        <span class="text-md font-bold">This application wasn't sourced by Copilot.</span>
        <span class="text-sm text-shade-800"
          >Effortlessly reclaim your time—flip the Copilot switch for future applications.</span
        >
      </div>
      <div class="flex flex-col gap-y-4" v-else>
        <div class="text-2xl font-bold">
          <div v-if="isScoreMissing">
            <span>Score unavailable</span>
          </div>
          <div class="flex gap-x-2" v-else>
            <span>{{ normalizedScore }}</span>
            <score-rating
              v-if="scoreDetails"
              :key="scoreDetails.jobApplicationId"
              :score="Number(normalizedScore)"
              :width="16"
              :height="16"
            >
            </score-rating>
          </div>
        </div>
        <div class="flex flex items-start gap-1 self-stretch rounded-md bg-tint-40 p-1">
          <img src="@/assets/svg/conversations/briefcase.svg" alt="Briefcase" />
          <span class="text-sm font-normal text-shade-800">
            {{ jobTitle }} <span v-if="jobId">[{{ jobId }}]</span>
          </span>
        </div>
        <div>
          <p
            class="score-summary text-sm text-shade-880"
            :class="{
              'text-shade-800': isLegacyScore || isScoreMissing,
              'ml-4': scoreVersion === 'v2',
            }"
            v-html="summaryText"
          ></p>
        </div>
        <div v-if="chips.length" class="flex flex-col gap-2">
          <div class="flex flex-wrap gap-2">
            <ScoreChip
              v-for="chip in chips"
              :key="chip.title"
              :chipTitle="chip.title"
              :chipText="chip.text"
              :color="chip.color"
              :should-show-dialog="true"
              :analytics-source="analyticsSource"
              :application-id="applicationId"
              :score="numericScore"
            />
          </div>

          <CopilotScoreFeedback
            v-if="userProfileId && !isLegacyScore && !isScoreMissing"
            :job-applicant-id="applicationId"
            :user-profile-id="userProfileId"
            :copilot-score="numericScore"
            :copilot-blurb="summaryText"
            :copilot-chips="chips"
          />
        </div>
        <div v-else-if="!isLegacyScore && !isScoreMissing">
          <p
            class="text-[10px] font-normal italic leading-[21px] tracking-[0.015px] text-shade-800"
          >
            This candidate was scored before the release of our updated scoring system.
          </p>
        </div>
      </div>
    </template>
  </div>
</template>

<style scoped>
.score-summary :deep(ul) {
  @apply !list-disc;
}
</style>
