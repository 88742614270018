import { useAtsEmployerApi, useAtsHelpDeskApi, useFFRestApi } from '@/composables/useApi';
import { useMeStore } from './me.store';
import type { Employer, GroupMembership, UserProfile } from '@factoryfixinc/ats-interfaces';
import { InternalError } from '../errors/internal.error';

export class MePersistence {
  private store = useMeStore();

  public async inviteEmployerMember(email: string): Promise<void> {
    const employer = this.store.employer;
    const userProfile = this.store.userProfile;

    if (!employer || !userProfile) {
      return;
    }

    const inviteUser = userProfile;
    const employerLocation = `${employer.city} ${employer.state}, USA`;

    const body = {
      location: employerLocation,
      inviteeEmail: email,
      inviterEmail: inviteUser.email,
      inviterFullName: `${inviteUser.nameFirst} ${inviteUser.nameLast}`,
      companyName: employer.name,
      employerId: employer.id,
    };

    const { error } = await useAtsEmployerApi('/employer/invite-new-member').post(body);

    if (error.value) {
      throw new Error(error.value);
    }
  }

  public async sendHelpRequest(email: string, message: string): Promise<void> {
    const employerId = this.store.employer?.id;

    const userName = [this.store.userProfile?.nameFirst, this.store.userProfile?.nameLast]
      .filter(Boolean)
      .join(' ');

    const body = {
      email,
      userName,
      message,
      url: window.location.href,
    };

    const { error } = await useAtsHelpDeskApi('/v1/notification', {
      headers: {
        'Employer-Id': `${employerId}`,
      },
    }).post(body);

    if (error.value) {
      throw new Error(error.value);
    }
  }

  public async updateUserProfile(
    employerId: Employer['id'],
    userProfileId: UserProfile['id'],
    data: Partial<UserProfile>,
  ): Promise<void> {
    const url = `/employer/${employerId}/user-profile/${userProfileId}`;
    const { error, statusCode } = await useFFRestApi(url).patch(data);

    if (error.value) {
      throw new InternalError('Failed to update user profile', {
        error: error.value,
        status: statusCode.value,
        data: { employerId, userProfileId, data },
      });
    }
  }

  public async updateUserAuthGroup(
    employerId: Employer['id'],
    userProfileId: UserProfile['id'],
    groupMembership: GroupMembership,
  ): Promise<void> {
    const url = `/employer/${employerId}/user-profile/${userProfileId}/user-auth-group`;
    const { error, statusCode } = await useFFRestApi(url).patch({ groupMembership });

    if (error.value) {
      throw new InternalError('Failed to update user profile auth group', {
        error: error.value,
        status: statusCode.value,
        data: { employerId, userProfileId, groupMembership },
      });
    }
  }

  public async updatePassword(userProfileId: UserProfile['id'], password: string): Promise<void> {
    const url = `/user-profile/${userProfileId}/password`;
    const { error, statusCode } = await useFFRestApi(url).put({ password });

    if (error.value) {
      throw new InternalError('Failed to update password', {
        error: error.value,
        status: statusCode.value,
        data: { userProfileId },
      });
    }
  }

  public async removeEmployerMember(
    employerId: Employer['id'],
    userProfileId: UserProfile['id'],
  ): Promise<void> {
    const url = `/v2/employer/${employerId}/user/${userProfileId}`;
    const { error, statusCode } = await useAtsEmployerApi(url).delete();

    if (error.value) {
      throw new InternalError('Failed to remove employer member', {
        error: error.value,
        status: statusCode.value,
        data: { employerId, userProfileId },
      });
    }
  }
}
